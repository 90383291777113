<template>
  <div class="jobs">
    <Head :headdata="headdata"></Head>
    <div class="jobsMain common-main">
      <h2 class="title_zh">
        招聘
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share/>
        </div>
      </h2>
      <h2 class="title_en">Job</h2>
      <!-- <p class="title_text">
        bicc以不断提升前瞻性研究经验和决策落地能力为目标，
        积累大量专题研究成果，致力于打造旅游综合开发运营知识库，
        始终追求立足行业前端。
      </p> -->
      <div class="htmlDom" v-html="htmlContent"></div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";
import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.jobHeaddata"),
      htmlContent: "",
    };
  },
  components: {
    Head,
    share
  },
  created() {
    this.getData();
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/jobs") // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
          if(res.data.code==1){
             this.htmlContent=res.data.data.recruit;
          }
         
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.jobs {
  height: 100%;
  width: 100%;
  .jobsMain {
    padding: 78px 200px 0 200px;
    .title_zh {
      color: #000;
      font-size: 34px;
      .fenxBox {
        float: right;
        cursor: pointer;
        .fenx {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 12px;
          color: #808080;
        }
      }
    }
    .title_en {
      // margin-bottom: 60px;
      font-size: 24px;
    }
    .title_text {
      font-size: 18px;
      max-width: 400px;
      // margin-bottom: 60px;
      color: #000;
    }
  }
}
</style>